/* tourOverviewStyles.css */
.tour-overview-content ul,
.tour-overview-content ol {
  list-style: disc inside !important; /* Example style, adjust as needed */
  padding-left: 20px !important; /* Adjust as needed */
}

.tour-overview-content ul ul,
.tour-overview-content ol ol {
  list-style-type: circle !important; /* Style for nested lists, adjust as needed */
}
